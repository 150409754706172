// components/Library.js

import './css/library.css';
import React, { useState, useEffect } from 'react';

const Library = () => {
    const [selectedCategory, setSelectedCategory] = useState('French');
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [tag, setTag] = useState('');
    const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000'; // Ensure this is your backend URL

    useEffect(() => {
        loadFiles(selectedCategory);
    }, [selectedCategory]);

    const loadFiles = async (category) => {
        try {
            let url = `${apiUrl}/api/files`;

            // If a category is specified and it's different from 'all', add the filter
            if (category && category !== 'all') {
                url = `${apiUrl}/api/files/${encodeURIComponent(category)}`;
            }

            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Error loading files: ${response.statusText}`);
            }
            const data = await response.json();
            if (Array.isArray(data)) {
                console.log("Files loaded:", data); // For debugging
                setFiles(data);
            } else {
                console.warn("The received data is not an array:", data);
                setFiles([]);
            }
        } catch (error) {
            console.error('Error loading files:', error);
            setFiles([]);
        }
    };

    const handleCategoryChange = (category) => {
        console.log("Category changed:", category); // For debugging
        setSelectedCategory(category);
        setSelectedFile(null); // Reset the selected file when category changes
    };

    const handleFileSelect = (file) => {
        console.log("File selected:", file); // For debugging
        setSelectedFile(file);
    };

    const handleFileUpload = async () => {
        if (!fileToUpload) {
            alert('Please select a file to upload!');
            return;
        }

        const formData = new FormData();
        formData.append('file', fileToUpload);
        formData.append('tag', tag);

        try {
            const response = await fetch(`${apiUrl}/api/files/${encodeURIComponent(selectedCategory)}`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                console.log("File uploaded successfully"); // For debugging
                await loadFiles(selectedCategory);
                setShowModal(false);
                setFileToUpload(null);
                setTag('');
            } else {
                const errorText = await response.text();
                console.error('Error uploading file:', response.statusText, errorText);
                alert('Error uploading the file. Please check the console for details.');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('Error uploading the file. Please check the console for details.');
        }
    };

    const handleFileDelete = async () => {
        if (!selectedFile) {
            alert('Please select a file to delete!');
            return;
        }

        try {
            const response = await fetch(`${apiUrl}/api/files/${selectedFile._id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                console.log("File deleted successfully"); // For debugging
                await loadFiles(selectedCategory);
                setSelectedFile(null);
            } else {
                const errorText = await response.text();
                console.error('Error deleting file:', response.statusText, errorText);
                alert('Error deleting the file. Please check the console for details.');
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            alert('Error deleting the file. Please check the console for details.');
        }
    };

    const getFileUrl = (file) => {
        const originalName = file.metadata.originalName || '';
        const lowerCaseName = originalName.toLowerCase();

        if (lowerCaseName.endsWith('.pdf')) {
            return `${apiUrl}/api/pdf/${encodeURIComponent(file.filename)}?t=${Date.now()}`;
        } else if (
            lowerCaseName.endsWith('.jpg') ||
            lowerCaseName.endsWith('.jpeg') ||
            lowerCaseName.endsWith('.png') ||
            lowerCaseName.endsWith('.gif')
        ) {
            return `${apiUrl}/api/image/${encodeURIComponent(file.filename)}?t=${Date.now()}`;
        } else if (lowerCaseName.endsWith('.mp4')) {
            return `${apiUrl}/api/video/${encodeURIComponent(file.filename)}?t=${Date.now()}`;
        } else {
            return `${apiUrl}/api/download/${file._id}`;
        }
    };

    // File rendering function, similar to FileList.js
    const renderFile = (file) => {
        const originalName = file.metadata.originalName || 'Unknown';
        const lowerCaseName = originalName.toLowerCase();
        const fileUrl = getFileUrl(file);

        console.log(`Rendering file: ${originalName}, URL: ${fileUrl}`); // For debugging

        if (lowerCaseName.endsWith('.pdf')) {
            return (
                <div className="file-pdf">
                    <embed
                        src={fileUrl}
                        type="application/pdf"
                        
                        onError={(e) => console.error("Error loading PDF:", e)}
                    />
                    
                </div>
            );
        } else if (lowerCaseName.endsWith('.mp4')) {
            return (
                <video style={{ width: '100%', height: '100%' }} controls>
                    <source src={fileUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            );
        } else if (
            lowerCaseName.endsWith('.jpg') ||
            lowerCaseName.endsWith('.jpeg') ||
            lowerCaseName.endsWith('.png') ||
            lowerCaseName.endsWith('.gif')
        ) {
            return (
                <img
                    src={fileUrl}
                    alt={originalName}
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                    onError={(e) => console.error("Error loading image:", e)}
                />
            );
        } else {
            return (
                <div className="file-download">
                    <a href={fileUrl} download={originalName || 'File'} className="download-link">
                        Download
                    </a>
                </div>
            );
        }
    };

    return (
        <div className="box">
            <div className="library-s">
                {/* Sidebar for categories */}
                <div className="sidebar-ly">
                    {['French', 'English', 'Spanish', 'Italian'].map((category, index) => (
                        <div
                            key={index}
                            className={`folder ${selectedCategory === category ? 'active' : ''}`}
                            onClick={() => handleCategoryChange(category)}
                        >
                            {category}
                        </div>
                    ))}
                </div>

                {/* File view */}
                <div className="file-view">
                    {Array.isArray(files) && files.length > 0 ? (
                        <div className="file-grid">
                            {files.map((file) => {
                                const originalName = file.metadata.originalName || '';
                                const lowerCaseName = originalName.toLowerCase();
                                const fileUrl = getFileUrl(file);

                                return (
                                    <div
                                        key={file._id}
                                        className={`file-item ${selectedFile && selectedFile._id === file._id ? 'selected' : ''}`}
                                        onClick={() => handleFileSelect(file)}
                                    >
                                        {renderFile(file)}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <p>No files available</p>
                    )}
                </div>

                {/* Preview and controls */}
                <div className="contain-preview">
                    <div className="file-preview">
                        {selectedFile ? (
                            renderFile(selectedFile)
                        ) : (
                            <p>No file selected</p>
                        )}
                        {selectedFile && <p>Tag: {selectedFile.metadata.tag || 'No tag'}</p>}
                    </div>

                    <div className="controls">
                        <button type="button" onClick={() => setShowModal(true)}>
                            Add File
                        </button>
                        <button type="button" onClick={handleFileDelete} disabled={!selectedFile}>
                            Delete
                        </button>
                    </div>
                </div>
            </div>

            {/* Upload Modal */}
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Upload a File</h3>
                        <input type="file" name="file" onChange={(e) => setFileToUpload(e.target.files[0])} />
                        <input
                            type="text"
                            placeholder="Add a tag (optional)"
                            value={tag}
                            onChange={(e) => setTag(e.target.value)}
                        />
                        <div className="modal-buttons">
                            <button type="button" onClick={handleFileUpload}>
                                Upload
                            </button>
                            <button type="button" onClick={() => setShowModal(false)}>
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Library;