// src/components/Library.js

import React, { useState, useEffect } from 'react';
import './css/library.css';
import { Dropdown } from './selecteur';

const Library = () => {
  const [files, setFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [fullscreenFile, setFullscreenFile] = useState(null);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_BACKEND_URL;

  // Fetch all files from the backend
  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/files`);
        if (!response.ok) {
          throw new Error('Failed to fetch files');
        }
        const data = await response.json();
        setFiles(data);
      } catch (err) {
        setError('no files');
        console.error(err);
      }
    };

    fetchFiles();
  }, [apiUrl]);

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleFileClick = (file) => {
    setFullscreenFile(file);
  };

  const handleCloseFullscreen = () => {
    setFullscreenFile(null);
  };

  // Filter files by selected category
  const filteredFiles =
    selectedCategory === 'all'
      ? files
      : files.filter((file) => file.metadata.category === selectedCategory);

  // Category options
  const categoryOptions = [
    { label: 'All', value: 'all' },
    { label: 'French', value: 'French' },
    { label: 'English', value: 'English' },
    { label: 'Spanish', value: 'Spanish' },
    { label: 'Italian', value: 'Italian' },
  ];

  // Render files based on their MIME type
  const renderFile = (file, isFullscreen = false) => {
    const originalName = file.metadata.originalName || 'Unknown';
    const filename = file.filename;
    const contentType = file.contentType || 'application/octet-stream';

    if (contentType.startsWith('image/')) {
      const imageUrl = `${apiUrl}/api/image/${encodeURIComponent(filename)}?t=${Date.now()}`;
      return (
        <div className="file-image-wrapper">
          <img src={imageUrl} alt={originalName} className="file-image" />
          {isFullscreen && (
            <a
              href={`${apiUrl}/api/download/${file._id}?download=true`}
              download={originalName}
              className="download-btn"
              onClick={(e) => e.stopPropagation()} // Prevent fullscreen close
            >
              Download
            </a>
          )}
        </div>
      );
    } else if (contentType === 'application/pdf') {
      const pdfUrl = `${apiUrl}/api/pdf/${encodeURIComponent(filename)}?t=${Date.now()}`;
      return (
        <div className="file-pdf">
          <embed
            src={pdfUrl}
            type="application/pdf"
            width="100%"
            height="600px"
            onError={(e) => {
              console.error("Error loading PDF:", e);
            }}
          />
          {isFullscreen && (
            <a
              href={`${apiUrl}/api/download/${file._id}?download=true`}
              download={originalName}
              className="download-btn"
              onClick={(e) => e.stopPropagation()} // Prevent fullscreen close
            >
              Download
            </a>
          )}
        </div>
      );
    } else if (contentType.startsWith('video/')) {
      const videoUrl = `${apiUrl}/api/video/${encodeURIComponent(filename)}?t=${Date.now()}`;
      return (
        <div>
          <video width="100%" height="300px" controls className="file-video">
            <source src={videoUrl} type={contentType} />
            Your browser does not support the video tag.
          </video>
          {isFullscreen && (
            <a
              href={`${apiUrl}/api/download/${file._id}?download=true`}
              download={originalName}
              className="download-btn"
              onClick={(e) => e.stopPropagation()} // Prevent fullscreen close
            >
              Download
            </a>
          )}
        </div>
      );
    } else {
      return <div>Unsupported file type</div>;
    }
  };

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="base">
      <div className="library">
        <h1>Library</h1>
        <div className="select-tag">
          <label htmlFor="category-filter">Select Category:</label>
          <Dropdown
            className="selecteur"
            label="Category"
            options={categoryOptions}
            onSelect={handleCategoryChange}
            selectedValue={selectedCategory}
          />
        </div>
        <div className="file-grid">
          {filteredFiles.length > 0 ? (
            filteredFiles.map((file) => (
              <div key={file._id} className="file-item" onClick={() => handleFileClick(file)}>
                {renderFile(file)} {/* Normal rendering without fullscreen */}
                <div className="file-name">{file.metadata.originalName}</div>
                <div className="tags">
                  {file.metadata.tag && (
                    <span className="tag">{file.metadata.tag}</span>
                  )}
                </div>
              </div>
            ))
          ) : (
            <p>No files available</p>
          )}
        </div>
      </div>

      {fullscreenFile && (
        <div className="fullscreen" onClick={handleCloseFullscreen}>
          {fullscreenFile.contentType === 'application/pdf' ? (
            <div className="file-pdf">
              <embed
                src={`${apiUrl}/api/pdf/${encodeURIComponent(fullscreenFile.filename)}?t=${Date.now()}`}
                type="application/pdf"
                width="90%"
                height="80%"
                onError={(e) => {
                  console.error("Error loading PDF in fullscreen:", e);
                }}
              />
              <div className="pdf-actions">
                <a
                  href={`${apiUrl}/api/download/${fullscreenFile._id}?download=true`}
                  download={fullscreenFile.metadata.originalName || 'File.pdf'}
                  className="download-btn"
                  rel="noopener noreferrer"
                  onClick={(e) => e.stopPropagation()} // Prevent closing fullscreen on click
                >
                  Download
                </a>
              </div>
            </div>
          ) : (
            renderFile(fullscreenFile, true) // Fullscreen rendering with download button
          )}
        </div>
      )}
    </div>
  );
};

export default Library;