import React, { useState, useEffect } from 'react';
import './css/user.css'; // Ensure the CSS file is correctly linked
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const User = () => {
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);

    // Fetch users from the API
    const fetchUsers = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/users-settings`);
            const data = await response.json();
            setUsers(data);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    // Fetch users when the component mounts
    useEffect(() => {
        fetchUsers();
    }, []);

    // Handle user selection
    const handleUserClick = (user) => {
        setSelectedUser(user);
    };

    // Cancel user selection
    const handleCancel = () => {
        setSelectedUser(null);
    };

    // Add or update user
    const handleAddOrUpdate = async (e) => {
        e.preventDefault();
        const updatedUser = {
            job: e.target.job.value,
            location: e.target.location.value,
            flag: e.target.flag.value, // Get flag from the dropdown
            lkdUrl: e.target.lkdUrl.value, // Add LinkedIn profile
        };

        try {
            if (selectedUser._id) {
                // Update existing user
                await fetch(`${apiUrl}/api/users-settings/${selectedUser._id}`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatedUser),
                });
            } else {
                // Add a new user
                await fetch(`${apiUrl}/api/users-settings`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatedUser),
                });
            }
            fetchUsers(); // Refresh user list after adding/updating
            handleCancel();
        } catch (error) {
            console.error('Error adding or updating user:', error);
        }
    };

    return (
        <div className="box">
            <div className="user">
                <h2>Users</h2>
                <div className="user-list">
                    {users.map((user) => (
                        <div key={user._id} className="user-item" onClick={() => handleUserClick(user)}>
                            <img src={user.photo} alt="user" />
                            <div>{user.givenName} {user.familyName}</div>
                            <div>
                                <a href={user.lkdUrl || '#'} target="_blank" rel="noopener noreferrer">
                                    
                                </a>
                            </div>
                            <div>{user.job}</div>
                            <div>{user.location}</div>
                            <div>{user.flag}</div>
                        </div>
                    ))}
                </div>
                {selectedUser && (
                    <div className="modal">
                        <form onSubmit={handleAddOrUpdate}>
                            <label htmlFor="job">Job:</label>
                            <input type="text" id="job" defaultValue={selectedUser.job} />

                            <label htmlFor="location">Location:</label>
                            <select id="location" defaultValue={selectedUser.location}>
                                <option value="Eskimoz FR">Eskimoz FR</option>
                                <option value="Eskimoz UK">Eskimoz UK</option>
                                <option value="Eskimoz IT">Eskimoz IT</option>
                                <option value="Eskimoz ES">Eskimoz ES</option>
                                <option value="Semtrix">Semtrix</option>
                                <option value="Neads">Neads</option>
                                <option value="Deeploy">Deeploy</option>
                                <option value="Staenk">Staenk</option>
                                <option value="Paper Club">Paper Club</option>
                                <option value="Senek">Senek</option>
                            </select>

                            <label htmlFor="flag">Flag:</label>
                            <select id="flag" defaultValue={selectedUser.flag}>
                                <option value="🇫🇷">🇫🇷 France</option>
                                <option value="🇺🇸">🇺🇸 United States</option>
                                <option value="🇬🇧">🇬🇧 United Kingdom</option>
                                <option value="🇩🇪">🇩🇪 Germany</option>
                                <option value="🇨🇦">🇨🇦 Canada</option>
                                <option value="🇮🇹">🇮🇹 Italy</option>
                                <option value="🇪🇸">🇪🇸 Spain</option>
                                <option value="🇧🇷">🇧🇷 Brazil</option>
                                <option value="🇯🇵">🇯🇵 Japan</option>
                                <option value="🇨🇳">🇨🇳 China</option>
                                <option value="🇮🇳">🇮🇳 India</option>
                                <option value="🇲🇽">🇲🇽 Mexico</option>
                                <option value="🇷🇺">🇷🇺 Russia</option>
                            </select>

                            <label htmlFor="lkdUrl">LinkedIn Profile:</label>
                            <input type="text" id="lkdUrl" defaultValue={selectedUser.lkdUrl} placeholder="LinkedIn URL" />

                            <button type="button" onClick={handleCancel}>Cancel</button>
                            <button type="submit">Save</button>
                        </form>
                    </div>
                )}
            </div>
        </div>
    );
};

export default User;
