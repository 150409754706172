import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/feed.css';
import { Dropdown, DropdownUser } from './selecteur';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Feed = () => {
    const [selectedOption, setSelectedOption] = useState('All'); // still unused, might want to remove
    const [feedView, setFeedView] = useState('Most Recent');
    const [posts, setPosts] = useState([]);
    const [users, setUsers] = useState([]); // Declare the users state

    useEffect(() => {
        fetchPosts(feedView);
        fetchUsers();
    }, [feedView]);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/users-all`, {
                withCredentials: true
            });
            setUsers(response.data.users);
        } catch (error) {
            console.error('Error fetching users', error);
        }
    };
    const viewMap = {
        'Most Recent': 'newest',
        'Oldest': 'oldest',
        'Most Impressions': 'most-impressions',
        'Least Impressions': 'least-impressions',
        'Most Comments': 'most-comments',
        'Least Comments': 'least-comments',
        'Most Likes': 'most-likes',
        'Least Likes': 'least-likes'
    };

    const handleCategorySelect = async (category, view) => {
        try {
            if (category === 'all') {
                const response = await axios.get(`${apiUrl}/api/posts/${viewMap[view]}`, {
                    withCredentials: true
                });
                console.log('All posts:', response.data);  
                setPosts(response.data);
            } else if (category === 'inspiration') {
                const response = await axios.get(`${apiUrl}/api/inspiration`, {
                    withCredentials: true
                });
                const links = response.data.posts.map(post => post.link);
                console.log('Inspiration posts:', links);
                setPosts(links);
            } else {
                console.error('Unknown category selected');
            }
        } catch (error) {
            console.error('Error fetching posts for category', error);
        }
    };
    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    
    const handleUserSelect = async (user) => {
        try {
            const response = await axios.get(`${apiUrl}/api/postsUser`, {
                params: { 
                    'userid': user.id  // Utiliser params pour envoyer l'id utilisateur
                },
                withCredentials: true
            });
    
            // Vérifie que la structure des données est correcte avant de les utiliser
            if (response.data && response.data.posts) {
                const links = response.data.posts[0].posts.map(post => post.link) // Assurer que posts est bien un tableau
                console.log('User posts:', links);
                setPosts(links);  // Mettre à jour l'état avec les liens des posts
            } else {
                console.error('Unexpected response structure:', response.data);
            }
        } catch (error) {
            console.error('Error fetching user posts', error);
        }
    };
    
    
    
    
    const fetchPosts = async (view) => {
        
        try {
            const response = await axios.get(`${apiUrl}/api/posts/${viewMap[view]}`, {
                withCredentials: true
            });
            setPosts(response.data);
        } catch (error) {
            console.error('Error fetching posts', error);
        }
    };

    const handleFeedViewChange = (event) => {
        setFeedView(event.target.value);
    };

    return (
        <div className='base'>
            <div className='Feed'>
                <div className='title'>
                    <h1>Feed</h1>
                    <div className='selecter-box' style={{ display: 'flex' }}>
                        <DropdownUser
                            label="Select a user"
                            options={users.map(user => ({
                                label: `${capitalizeFirstLetter(user.givenName)} ${capitalizeFirstLetter(user.familyName)}`,
                                value: user.id
                            }))}
                            onSelect={(userId) => {
                                const user = users.find(user => user.id === userId);
                                handleUserSelect(user);
                            }}
                        />
                        <Dropdown
                            label="All"
                            options={[
                                { label: 'All', value: 'all' },
                                { label: 'Inspiration', value: 'inspiration' }
                            ]}
                            onSelect={(value) => handleCategorySelect(value, feedView)} // Passe feedView ici
                        />
                    </div>
                </div>

                <div className='view-selection'>
                    <label htmlFor="feedView">Select the feed view: </label>
                    <select id="feedView" className='feedView' value={feedView} onChange={handleFeedViewChange}>
                        <option value="Most Recent">Most Recent</option>
                        <option value="Oldest">Oldest</option>
                        <option value="Most Impressions">Most Impressions</option>
                        <option value="Least Impressions">Least Impressions</option>
                        <option value="Most Comments">Most Comments</option>
                        <option value="Least Comments">Least Comments</option>
                        <option value="Most Likes">Most Likes</option>
                        <option value="Least Likes">Least Likes</option>
                    </select>
                </div>

                {posts.length > 0 && (
                    <div className='post'>
                        {posts.map((post, index) => (
                            <iframe
                                key={index}
                                src={post}
                                height="583"
                                width="504"
                                allowFullScreen
                                title="Embedded Post"
                                loading='lazy'
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Feed;
