import React from 'react';
import './css/awards.css';

const Awards = () => {
    const monthlyAwards = [
        { position: 'First', name: 'Trip for 2 to Morocco', price: '' },
        { position: 'Second', name: '750 €', price: '' },
        { position: 'Third', name: '500 €', price: '' },
        { position: 'Fourth', name: '250€', price: '' },
        { position: 'Fifth', name: '250 €', price: '' },
    ];

    const exceptionalAwards = [
        { impressions: '500 000 impressions / month', price: '5 000 €' },
        { impressions: '1 000 000 impressions / month', price: '10 000 €' },
    ];

    return (
        <div className='base'>
        
        <div className='awards'>
            <h1>Awards</h1>
            <div className='price'>
            <h2 className='monthly-title'>Price of month</h2>
            <div className='monthly-awards'>
                {monthlyAwards.map((item, index) => (
                    <div key={index} className='award-item'>
                        <h3>{item.position} price</h3>
                        <p>{item.name}</p>
                    </div>
                ))}
            </div>
            <h2 className='exclusive-title'>Exclusive Price</h2>
            <div className='exclusive-awards'>
                {exceptionalAwards.map((item, index) => (
                    <div key={index} className='exclusive-item'>
                        <p>{item.impressions}</p>
                        <h3>{item.price}</h3>
                    </div>
                ))}
            </div>
            </div>
            
        </div>
        </div>
    );
};

export default Awards;
