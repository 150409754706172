import React, { useState, useEffect } from 'react';
import './css/home.css';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import { Dropdown } from './selecteur';
import axios from 'axios'; // Axios for HTTP requests
import GraphComponent from './customGraphe';
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const InfoDisplay = ({ label, value, evolution, participants }) => {
    const getEvolutionColor = (evolution) => {
        let numericValue;
        if (typeof evolution === 'string' && evolution.includes('%')) {
            numericValue = parseFloat(evolution.replace('%', ''));
        } else {
            numericValue = parseFloat(evolution);
        }
        if (numericValue === 0) {
            return 'orange';
        }
        return numericValue >= 0 ? 'green' : 'red';
    };

    const formatEvolution = (label, evolution) => {
        if (label === 'Ranking') {
            return Math.abs(evolution);
        }
        if (typeof evolution === 'string' && evolution.includes('%')) {
            return evolution;
        }
        return `${Math.abs(evolution)}%`;
    };

    const getEvolutionSymbol = (evolution) => {
        let numericValue;
        if (typeof evolution === 'string' && evolution.includes('%')) {
            numericValue = parseFloat(evolution.replace('%', ''));
        } else {
            numericValue = parseFloat(evolution);
        }
        if (numericValue === 0) {
            return <span style={{ fontSize: '1.5em' }}>=</span>;
        }
        return numericValue > 0 ? <span>&#8593;</span> : <span>&#8595;</span>;
    };

    // Définir numericValue ici pour l'utiliser dans le rendu
    let numericValue;
    if (typeof evolution === 'string' && evolution.includes('%')) {
        numericValue = parseFloat(evolution.replace('%', ''));
    } else {
        numericValue = parseFloat(evolution);
    }

    return (
        <div className='infoDisplay'>
            <div className='data'>
                <h2 className='label'>{label}</h2>
                <div className='box-box'>
                    <p className='value'>{value}</p>
                    <p className='evolution' style={{ color: getEvolutionColor(evolution) }}>
                        {getEvolutionSymbol(evolution)} {numericValue === 0 ? '' : formatEvolution(label, evolution)}
                    </p>
                </div>
                <p className='partic'>{participants}</p>
            </div>
        </div>
    );
};

const ProgressBar = ({ posts }) => {
    const progress = Math.min((posts / 5) * 100, 100);
    return (
        <div className='infoDisplay'>
            <div className='data'>
                <h2 className='label'>Progress Bar</h2>
                <div className='progress-bar'>
                    <div style={{ border: '1px solid #ddd', borderRadius: '5px', width: '80%', height: '8.5px', margin:'auto', backgroundColor:'#CEE0EC' }}>
                        <div style={{
                            width: `${progress}%`, 
                            height: '100%', 
                            backgroundImage: 'linear-gradient(to right, #7772EB, #73ACFB, #73CDFA)', 
                            borderRadius: '5px'
                        }}></div>
                    </div>
                    <div className='nb-post'>{posts}/5</div>
                </div>
                <p className='partic'>posts</p>
            </div>
        </div>
    );
};

const StatsGraph = ({ data, options }) => (
    <div style={{ margin: '20px auto', width:'90%', position: 'relative'}}>
        <Bar data={data} options={options} />
    </div>
);

const Home = () => {
    const [infoType, setInfoType] = useState('views');
    const [nbUsers, setNbUsers] = useState(0);
    const [upDataSets, setUpDataSets] = useState({
        posts: 0,
        views: 0,
        score: 0,
        ranking: 0
    });
    const [pourcentDataSets, setPourcentDataSets] = useState({
        posts: 0,
        views: 0,
        score: 0,
        ranking: 0
    });

    // Initialize datasets with default values
    const defaultMonthData = {
        posts: 0,
        score: 0,
        view: 0,
        rank: 0,
    };

    const [dataSets, setDataSets] = useState({
        1: defaultMonthData,
        2: defaultMonthData,
        3: defaultMonthData,
        4: defaultMonthData,
        5: defaultMonthData,
        6: defaultMonthData,
        7: defaultMonthData,
        8: defaultMonthData,
        9: defaultMonthData,
        10: defaultMonthData,
        11: defaultMonthData,
        12: defaultMonthData,
    });
    const updateDataSets = (months) => {
        const updatedDataSets = {
            1: months.jan || defaultMonthData,
            2: months.feb || defaultMonthData,
            3: months.mar || defaultMonthData,
            4: months.apr || defaultMonthData,
            5: months.may || defaultMonthData,
            6: months.jun || defaultMonthData,
            7: months.jul || defaultMonthData,
            8: months.aug || defaultMonthData,
            9: months.sep || defaultMonthData,
            10: months.oct || defaultMonthData,
            11: months.nov || defaultMonthData,
            12: months.dec || defaultMonthData
        };

        console.log('Updated data sets:', updatedDataSets);
        setDataSets(updatedDataSets);
    };

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: infoType.charAt(0).toUpperCase() + infoType.slice(1),
                data: Object.values(dataSets).map(item => item[infoType] !== undefined ? item[infoType] : 0),
                backgroundColor: '#74C8EF',
                borderRadius: 7,
                barThickness: 35,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                grid: {
                    display: false,  
                    drawBorder: false 
                }
            },
            y: {
                beginAtZero: true,
                grid: {
                    display: true,  
                    color: '#EEE', 
                },
                ticks: {
                    callback: function(value) {
                        if (value >= 1000) {
                            return (value / 1000) + 'k'; 
                        }
                        return value;
                    }
                }
            }
        },
        plugins: {
            tooltip: {
                enabled: false,
                external: function(context) {
                    let tooltipEl = document.getElementById('chartjs-tooltip');
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.className = 'chartjs-tooltip';
                        document.body.appendChild(tooltipEl);
                    }
                    const tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = 0;
                        return;
                    }
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                        tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                        tooltipEl.classList.add('no-transform');
                    }
                    if (tooltipModel.body) {
                        const titleLines = tooltipModel.title || [];
                        const bodyLines = tooltipModel.body.map(b => b.lines);
                        let innerHtml = '<div class="custom-tooltip">';
                        titleLines.forEach(function(title) {
                            innerHtml += `<div class="tooltip-title">${title} 2024</div>`;
                        });
                        bodyLines.forEach(function(body, i) {
                            const monthIndex = tooltipModel.dataPoints[0].dataIndex;
                            const views = dataSets[monthIndex].views || 'N/A';
                            const score = dataSets[monthIndex].score || 'N/A';
                            const posts = dataSets[monthIndex].posts || 'N/A';
                            const ranking = dataSets[monthIndex].rank || 'N/A';
                            const customBody = `
                                <div class="tooltip-body"><span>Ranking:</span> <span>#${ranking}</span></div>
                                <div class="tooltip-body"><span>Score:</span> <span>${score}</span></div>
                                <div class="tooltip-body"><span>Posts:</span> <span>${posts}</span></div>
                                <div class="tooltip-body"><span>Views:</span> <span>${views}</span></div>
                            `;
                            innerHtml += customBody;
                        });
                        innerHtml += '</div>';
                        tooltipEl.innerHTML = innerHtml;
                    }
                    const position = context.chart.canvas.getBoundingClientRect();
                    tooltipEl.style.opacity = 1;
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                }
            },
            legend: {
                display: false
            }
        }
    };

    // Fetch ranking and datasets
    useEffect(() => {
        const fetchData = async () => {
            try {
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth() + 1; // getMonth() is zero-based
                const currentYear = currentDate.getFullYear();

                const rankingResponse = await axios.get(`${apiUrl}/api/ranking-user`, {
                    params: { month: currentMonth, year: currentYear },
                    withCredentials: true 
                });
                const nbUsersResponse = await axios.get(`${apiUrl}/api/users-all`, {
                    withCredentials: true
                });
                console.log('Nb users:', nbUsersResponse.data.users.length);
                setNbUsers(nbUsersResponse.data.users.length);
                
                const previousMonth = currentMonth === 1 ? 12 : currentMonth - 1;
                const previousYear = currentMonth === 1 ? currentYear - 1 : currentYear;

                const rankingLastMonth = await axios.get(`${apiUrl}/api/ranking-user`, {
                    params: { month: previousMonth, year: previousYear },
                    withCredentials: true
                });
                
                const dataGrapheResponse = await axios.get(`${apiUrl}/api/graphe-stats`, {
                    withCredentials: true
                });
                
                const dataSetsResponse = await axios.get(`${apiUrl}/api/user-stat`, {
                    withCredentials: true 
                });
                console.log('Data fetched:', dataSetsResponse.data);
                setPourcentDataSets({

                    ranking: rankingLastMonth.data.ranking.rank - rankingResponse.data.ranking.rank

                });

                // Update other datasets (example ranking)
                setUpDataSets({
                    nbPosts: dataSetsResponse.data.posts,
                    nbViews: dataSetsResponse.data.views >= 1000000 
                        ? `${(dataSetsResponse.data.views / 1000000).toFixed(1)}M` 
                        : dataSetsResponse.data.views >= 1000 
                            ? `${(dataSetsResponse.data.views / 1000).toFixed(1)}k` 
                            : dataSetsResponse.data.views,
                    score: dataSetsResponse.data.score >= 1000000 
                        ? `${(dataSetsResponse.data.score / 1000000).toFixed(1)}M`
                        : dataSetsResponse.data.score >= 1000 
                            ? `${(dataSetsResponse.data.score / 1000).toFixed(1)}k` 
                            : dataSetsResponse.data.score,
                    ranking: dataSetsResponse.data.rank,
                    rankingStat: dataSetsResponse.data.rankingStat,
                    scoreStat: dataSetsResponse.data.scoreStat,
                    postsStat: dataSetsResponse.data.postStat,
                    viewsStat: dataSetsResponse.data.viewsStat
                });
                
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the fetch function on mount
    }, []);

    return (
        <div className='base'>
            <div className='dashboard'>
                <div className='pad'>
                    <h1>Dashboard</h1>
                    <div className='dashboard-data'>
                        <div className='haeder-stat' >
                            <InfoDisplay label="Ranking" value={`#${upDataSets.ranking || 0}`} evolution={`${upDataSets.rankingStat || 0}`} participants={`on ${nbUsers} participants`} />
                            <InfoDisplay label="Posts" value={upDataSets.nbPosts|| 0} evolution={`${upDataSets.postsStat|| 0}%`} participants="posts" />
                            <InfoDisplay label="Views" value={upDataSets.nbViews|| 0} evolution={`${upDataSets.viewsStat|| 0}%`} participants="impressions" />
                            <InfoDisplay label="Score" value={upDataSets.score|| 0} evolution={`${upDataSets.scoreStat|| 0}%`} participants="points" />
                            <ProgressBar posts={upDataSets.nbPosts || 0} />
                        </div>
                        
                        <div className='graphe-stat' style={{ position: 'relative' }}>
                            <div style={{}}>
                            </div>
                            
                            <GraphComponent />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
