import React, { useState ,useEffect} from 'react';
import './css/settings.css';
import './settings/css/menu.css'; 
import Menu from './settings/menu'; 
import SlideToggle from './settings/ToggleSwitch'; 
import DiscreteSliderSteps from './settings/DiscreteSliderSteps'; 
import FeedList from './settings/FeedList';
import User from './settings/user';
import Tips from './settings/Tips';
import Library from './settings/Library';
import AutoLike from './settings/AutoLike';
import useUserInfo from './userProfil';

const Settings = () => {
    const { userInfo, error } = useUserInfo(); 

    const [isAdmin, setIsAdmin] = useState(false); 
    const [activeTab, setActiveTab] = useState('Settings'); 

    useEffect(() => {
        if (userInfo) {
            setIsAdmin(userInfo.admin);
        }
    }, [userInfo]);

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!userInfo) {
        return <div>Loading...</div>;
    }

    const renderTabContent = () => {
        switch (activeTab) {
            case 'Settings':
                return (
                    <div className="">
                        <div className='composant'>
                        <SlideToggle />
                        <DiscreteSliderSteps/>
                        </div>
                       
                    </div>
                );
            case 'Feed':
                return <div className="content"><FeedList/></div>;
            case 'Utilisateur':
                return <div className="content"><User/></div>;
            case 'Tips':
                return <div className="content"><Tips/></div>;
            case 'Library':
                return <div className="content"><Library/></div>;
            case 'AutoLike':
                return <div className="content"><AutoLike/></div>;
            default:
                return <div className="content"><h2>settings</h2></div>;
        }
    };

    return (
        <div className='base'>
            <div className='settings-container'>
                <h1>Settings</h1>
                {isAdmin ? (
                    <div className="admin-section">
                        <Menu activeTab={activeTab} onTabClick={setActiveTab} />
                        <div className={`background-slide ${activeTab ? 'slide-in' : ''}`}>
                            {renderTabContent()}
                        </div>
                    </div>
                ) : (
                    <div className="non-admin-section">
                        <SlideToggle />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Settings;
