// Dashboard.js
import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Home from './Home';
import Feed from './Feed';
import Ranking from './Ranking';
import Awards from './Awards';
import Tips from './Tips';
import Library from './Library';
import Settings from './Settings';
import './css/dashboard.css';
import Popup from './popup';
function Dashboard() {
  const [activePage, setActivePage] = useState('Home');

  const renderContent = () => {
    switch (activePage) {
      case 'Home':
        return <Home />;
      case 'Feed':
        return <Feed />;
      case 'Ranking':
        return <Ranking />;
      case 'Awards':
        return <Awards />;
      case 'Tips':
        return <Tips />;
      case 'Library':
        return <Library />;
      case 'Settings':
        return <Settings />;
      default:
        return <Ranking />;
    }
  };

  return (
    <div className="dashboard">
      <Popup />
      <Sidebar activePage={activePage} setActivePage={setActivePage} />
      <div className="content">
        {renderContent()}
      </div>
    </div>
  );
}

export default Dashboard;
