import React, { useState, useEffect } from 'react';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Popup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const [userId, setUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // Nouvel état pour le chargement

    // Automatiquement vérifier la condition du backend au montage du composant
    useEffect(() => {
        checkBackendCondition();
    }, []);  // Le tableau vide assure que l'effet ne s'exécute qu'une seule fois

    const checkBackendCondition = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/extensions`, {
                withCredentials: true
            });
            setUserId(response.data.id);
            console.log(response);
            // Attendre la réponse du backend avant de définir l'affichage de la popup
            if (!response.data.user.valid) {
                setShowPopup(true);
                console.log(response.data);
            } else {
                setShowPopup(false);
            }
        } catch (error) {
            console.error('Error checking condition:', error);
            setPopupMessage('An error occurred while checking the condition.');
            setShowPopup(true);  // Afficher la popup en cas d'erreur
        } finally {
            setIsLoading(false); // Arrêter le chargement une fois la requête terminée
        }
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    return (
        <div>
            {/* Afficher la popup uniquement si la requête est terminée et qu'il faut l'afficher */}
            {!isLoading && showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close" onClick={closePopup}>&times;</span>
                        <div>
                        <h2>Your LinkedIn session has expired</h2>
                            <p>To continue using the application, please follow these steps to refresh your LinkedIn session cookie:</p>
                            <ol>
                                <li><strong>Add the Chrome extension</strong>: <a href="https://chrome.google.com/webstore/detail/gfpnlenlegcaloameaaaaplaahpoeoel" target="_blank">Extension link</a> <br />If you haven't installed the extension yet, click the link above to add it to your browser.</li>
                                
                                <li><strong>Log in to LinkedIn</strong>: Go to <a href="https://www.linkedin.com" target="_blank">www.linkedin.com</a> and sign in to your account if you're not already logged in.</li>
                                
                                <li><strong>Open the extension</strong>: Once logged in to LinkedIn, click the extension icon in your Chrome browser toolbar.</li>
                                
                                <li><strong>Enter the ID provided in this pop-up</strong>: Please copy the following user ID and paste it into the extension:<br />
                                <code>{userId}</code></li>
                                
                                <li><strong>Automatic session cookie retrieval</strong>: After submitting the ID, the extension will automatically retrieve your LinkedIn session cookie, allowing you to use the application.</li>
                            </ol>
                            <p><strong>Note</strong>: The session cookie is only valid for a limited time. It’s normal to have to refresh your session regularly by following these steps.</p>
                        </div>
                    </div>
                </div>
            )}

            <style>{`
                .popup {
                    position: fixed;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9999;
                }
                .popup-content {
                    background: white;
                    padding: 20px;
                    border-radius: 5px;
                    text-align: center;
                    width: 500px;
                    position: relative;
                }
                .close {
                    position: absolute;
                    top: 10px;
                    right: 20px;
                    cursor: pointer;
                    font-size: 18px;
                }
                h2 {
                    margin-bottom: 30px;
                }
                li {
                    margin-bottom: 25px;
                }
            `}</style>
        </div>
    );
};

export default Popup;
