import React, { useState, useEffect } from 'react';
import './css/ranking.css';
import linkedinIcon from '../img/icons/linkedinIcon.png';
import { Dropdown } from './selecteur';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_BACKEND_URL;

// Utility function to get border class based on rank
const getBorderClass = (rank) => {
    if (rank === 1) return 'first-place';
    if (rank === 2) return 'second-place';
    if (rank === 3) return 'third-place';
    return '';
};

// Function to format the score (e.g., 1500 -> 1.5K)
const formatScore = (score) => {
    return score >= 1000 ? (score / 1000).toFixed(1) + 'K' : score.toString();
};

// Function to format the progression (rank change)
const formatProgression = (progression) => {
    if (progression === null || progression === 0) return '=';
    return progression > 0 ? `+${progression}` : progression.toString();
};

// Ranking Item component to display each ranked person
const RankingItem = ({ rank, name, photo, job, entity, flag, score, progression, linkedinProfile }) => (
    <div className={`rankingItem ${getBorderClass(rank)}`}>
        <div className="profile">
            <img src={photo} className="profileImage" alt={`${name} profile`} />
            <div className='name_flag'>
                <div className="name">{name}</div>
                <p>{flag}</p>
            </div>
        </div>
        <a href={linkedinProfile} target="_blank" rel="noopener noreferrer" className="linkedinButton">
            <img src={linkedinIcon} alt="LinkedIn" className="linkedinIcon" />
        </a>
        <div className="title">{job || "N/A"}</div>
        <div className="location">{entity || "N/A"}</div>
        <div className="score">{formatScore(score)}</div>
        <div className='rank'>
            <div className='rankNumber'>{rank}</div>
            <div className={`change ${progression > 0 ? 'positive' : progression < 0 ? 'negative' : 'neutral'}`}>
                {formatProgression(progression)}
            </div>
        </div>
    </div>
);

// List of rankings, mapped to RankingItem components
const RankingList = ({ rankings }) => {
    if (!rankings || !Array.isArray(rankings) || rankings.length === 0) {
        return;
    }

    return (
        <div>
            {rankings.map((item, index) => (
                <RankingItem key={index} {...item} />
            ))}
        </div>
    );
};

// Recalculate progression and rank based on filtered city results
const recalculateRanksForCity = (rankings) => {
    // Sort by score
    rankings.sort((a, b) => parseFloat(b.score) - parseFloat(a.score));

    // Assign new rank and calculate progression
    return rankings.map((person, index) => ({
        ...person,
        rank: index + 1, // New rank based on filtered data
        change: calculateProgression(person.previousRank, index + 1) // Calculate the change
    }));
};

// Function to calculate rank progression
const calculateProgression = (previousRank, currentRank) => {
    if (previousRank === null || previousRank === undefined) return '='; // No change if rank is undefined
    const diff = previousRank - currentRank;
    return diff > 0 ? `+${diff}` : diff < 0 ? `${diff}` : '=';
};

const RankingPage = () => {
    // Get the current month and year
    const currentDate = new Date();
    const currentMonth = (currentDate.getMonth() + 1).toString(); // Months are zero-based, so +1
    const currentYear = currentDate.getFullYear().toString();

    const [selectedPeriod, setSelectedPeriod] = useState(currentMonth); // Default to current month
    const [selectedLocation, setSelectedLocation] = useState('all');
    const [selectedYear, setSelectedYear] = useState(currentYear); // Default to current year
    const [rankings, setRankings] = useState([]); // Initialize rankings as an empty array
    const [loading, setLoading] = useState(true);

    // Fetch rankings when the page loads or filter changes
    useEffect(() => {
        const fetchRankings = async () => {
            try {
                setLoading(true);

                // Determine URL based on selected period (all-year or specific month)
                let url = selectedPeriod === 'all' ? `${apiUrl}/api/ranking-years` : `${apiUrl}/api/ranking`;

                // Set parameters based on period and year
                let params = selectedPeriod === 'all'
                    ? { year: selectedYear } // For yearly ranking
                    : { month: selectedPeriod, year: selectedYear }; // For monthly ranking

                // Make GET request with query parameters
                const response = await axios.get(url, {
                    params, // Pass params here
                    withCredentials: true // Include cookies if needed
                });

                const data = response.data;

                // Log the data from backend to check if it’s received correctly
                console.log('Data received from backend:', data);

                // Ensure rankings is an array
                if (data && Array.isArray(data.ranking)) {
                    setRankings(data.ranking); // Extract the 'ranking' array from the response
                } else {
                    setRankings([]); // Fallback to an empty array if data is not in expected format
                }

            } catch (error) {
                console.error('Error fetching rankings:', error);
                setRankings([]); // Set to empty array in case of error
            } finally {
                setLoading(false);
            }
        };

        fetchRankings(); // Call the fetch function when component mounts or dependencies change
        
    }, [selectedPeriod, selectedYear]);
    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];

    // Filter rankings by location and recalculate ranks
    const filteredRankings = selectedLocation === 'all'
        ? rankings
        : recalculateRanksForCity(rankings.filter(item => item.entity === selectedLocation));

    return (
        <div className='base'>
            <div className='ranking'>
                <h1>Ranking</h1>
                <div className='group-filter'>
                    <Dropdown
                        label="All"
                        options={[
                            { value: 'all', label: 'All' },
                            { value: 'Eskimoz FR', label: 'Eskimoz FR' },
                            { value: 'Eskimoz UK', label: 'Eskimoz UK' },
                            { value: 'Eskimoz IT', label: 'Eskimoz IT' },
                            { value: 'Eskimoz ES', label: 'Eskimoz ES' },
                            { value: 'Semtrix', label: 'Semtrix' },
                            { value: 'Neads', label: 'Neads' },
                            { value: 'Deeploy', label: 'Deeploy' },
                            { value: 'Staenk', label: 'Staenk' },
                            { value: 'Paper Club', label: 'Paper Club' },
                            { value: 'Senek', label: 'Senek' }
                        ]}
                        value={selectedLocation}
                        onSelect={setSelectedLocation}
                    />
                    <Dropdown
                        label={monthNames[currentDate.getMonth()]}
                        options={[
                            { value: 'all', label: 'All' },
                            { value: '9', label: 'September' },
                        ]}
                        value={selectedPeriod}
                        onSelect={setSelectedPeriod}
                    />
                    <Dropdown
                        label={currentYear}
                        options={[
                            { value: '2024', label: '2024' }
                        ]}
                        value={selectedYear}
                        onSelect={setSelectedYear}
                    />
                </div>

                <div className='title-desc'>
                    <h4 className='name'>Name</h4>
                    <h4 className='profil'>Profil</h4>
                    <h4 className='posts'>Job</h4>
                    <h4 className='enthie'>Entity</h4>
                    <h4 className='scores'>Score</h4>
                    <h4 className='rank'>Rank</h4>
                </div>
                
                {loading ? (
                    <p>Loading rankings...</p>
                ) : (
                    <RankingList className='rankings' rankings={filteredRankings} />
                )}
            </div>
        </div>
    );
};

export default RankingPage;
