import React, { useState, useEffect } from 'react';
import './css/FeedList.css'; // Assurez-vous que les styles soient dans ce fichier CSS
const apiUrl = process.env.REACT_APP_BACKEND_URL
const FeedList = () => {
    const [posts, setPosts] = useState([]);  // Liste des posts d'inspiration
    const [selectedLink, setSelectedLink] = useState('');  // Lien sélectionné pour prévisualisation
    const [checkedPosts, setCheckedPosts] = useState([]);  // Liste des posts sélectionnés pour suppression

    // Récupérer les posts d'inspiration lors du chargement du composant
    useEffect(() => {
        fetchInspirationPosts();
    }, []);

    // Fonction pour récupérer les posts d'inspiration depuis l'API
    const fetchInspirationPosts = async () => {
        try {
            const response = await fetch(`${apiUrl}/api/inspiration`);
            const data = await response.json();
            setPosts(data.posts);  // Stocker les posts dans l'état, incluant l'ID et le lien
        } catch (error) {
            console.error('Error fetching inspiration posts:', error);
        }
    };

    // Fonction pour ajouter un nouveau lien d'inspiration
    const addLink = async () => {
        const newLink = prompt("Please enter the new LinkedIn link:");
        if (newLink) {
            try {
                const response = await fetch(`${apiUrl}/api/inspiration`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ link: newLink })
                });
                const data = await response.json();
                setPosts([...posts, data.posts]);  // Ajouter le nouveau post à la liste
            } catch (error) {
                console.error('Error adding inspiration post:', error);
            }
        }
    };

    // Fonction pour supprimer les posts sélectionnés
    const removeLinks = async () => {
        try {
            for (let post of checkedPosts) {
                await fetch(`${apiUrl}/api/inspiration`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ id: post._id })  // Supprimer par ID
                });
            }
            setPosts(posts.filter(post => !checkedPosts.includes(post)));  // Mettre à jour la liste après suppression
            setCheckedPosts([]);  // Réinitialiser les posts cochés
        } catch (error) {
            console.error('Error removing inspiration posts:', error);
        }
    };

    // Gestion de la sélection des liens à supprimer
    const toggleCheck = (post) => {
        const newCheckedPosts = checkedPosts.includes(post)
            ? checkedPosts.filter(p => p !== post)
            : [...checkedPosts, post];
        setCheckedPosts(newCheckedPosts);
    };

    // Gestion de la sélection du lien pour l'aperçu
    const handleLinkSelection = (link) => {
        if (selectedLink === link) {
            setSelectedLink('');  // Désélectionner si le même lien est cliqué
        } else {
            setSelectedLink(link);
        }
    };

    return (
        <div className='feedList'>
            <div className="toolbar">
                <button onClick={removeLinks} className="delete-button" disabled={checkedPosts.length === 0}>🗑️</button>
                <button onClick={addLink} className="add-button">+</button>
            </div>
            <ul>
                {posts.map((post, index) => (
                    <li key={index} className={selectedLink === post.link ? 'selected' : ''}>
                        <input
                            type="checkbox"
                            checked={checkedPosts.includes(post)}
                            onChange={() => toggleCheck(post)}
                        />
                        <span onClick={() => handleLinkSelection(post.link)}>{post.link}</span>
                        {selectedLink === post.link && (
                            <div className="preview">
                                <iframe src={post.link} width="500" height="300" allowFullScreen></iframe>
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default FeedList;
