import React, { useState } from 'react';
import { Home, HomeHover, Awards, AwardsHover, Settings, Feed, FeedHover, Ranking, RankingHover, Tips, TipsHover, Library, LibraryHover } from '../img/icons';
import './css/sidebar.css';
import logoEskimoz from '../img/logoEskimoz.svg';
import useUserInfo from './userProfil'; 




function Sidebar({ activePage, setActivePage }) {
  const [hoveredItem, setHoveredItem] = useState(activePage);
  const { userInfo, error } = useUserInfo(); 

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!userInfo) {
      return <div>Loading...</div>;
  } 

  const handleMouseEnter = (item) => {
    setHoveredItem(item);
  };

  const handleMouseLeave = () => {
    setHoveredItem(activePage);
  };

  const handleClick = (item) => {
    setActivePage(item);
    setHoveredItem(item);
  };

  const getItemPosition = (item) => {
    const items = ['Home', 'Feed', 'Ranking', 'Awards', 'Tips', 'Library'];
    const index = items.indexOf(item);
    if (index === -1) return 0;
    return index * 50;
  };

  return (
    <div className="sidebar">
      <div className="logoEsk">
        <img src={logoEskimoz} alt="Logo" className="logo" />
      </div>
      
      <div className="profile">
        <img src={userInfo.photo} alt="Profile" />
        <p style={{ fontWeight: '500' }}>{userInfo.givenName} {userInfo.familyName}</p>
      </div>
      
      <ul className="menu">
        <div className="indicator" style={{ top: `${getItemPosition(hoveredItem)}px` }}></div>
        <li>
          <button
            className={activePage === 'Home' ? 'active' : ''}
            onClick={() => handleClick('Home')}
            onMouseEnter={() => handleMouseEnter('Home')}
            onMouseLeave={handleMouseLeave}
          >
            {hoveredItem === 'Home' || activePage === 'Home' ? <HomeHover className="icon" /> : <Home className="icon" />} Home
          </button>
        </li>
        <li>
          <button
            className={activePage === 'Feed' ? 'active' : ''}
            onClick={() => handleClick('Feed')}
            onMouseEnter={() => handleMouseEnter('Feed')}
            onMouseLeave={handleMouseLeave}
          >
            {hoveredItem === 'Feed' || activePage === 'Feed' ? <FeedHover className="icon" /> : <Feed className="icon" />} Feed
          </button>
        </li>
        <li>
          <button
            className={activePage === 'Ranking' ? 'active' : ''}
            onClick={() => handleClick('Ranking')}
            onMouseEnter={() => handleMouseEnter('Ranking')}
            onMouseLeave={handleMouseLeave}
          >
            {hoveredItem === 'Ranking' || activePage === 'Ranking' ? <RankingHover className="icon" /> : <Ranking className="icon" />} Ranking
          </button>
        </li>
        <li>
          <button
            className={activePage === 'Awards' ? 'active' : ''}
            onClick={() => handleClick('Awards')}
            onMouseEnter={() => handleMouseEnter('Awards')}
            onMouseLeave={handleMouseLeave}
          >
            {hoveredItem === 'Awards' || activePage === 'Awards' ? <AwardsHover className="icon" /> : <Awards className="icon" />} Awards
          </button>
        </li>
        <li>
          <button
            className={activePage === 'Tips' ? 'active' : ''}
            onClick={() => handleClick('Tips')}
            onMouseEnter={() => handleMouseEnter('Tips')}
            onMouseLeave={handleMouseLeave}
          >
            {hoveredItem === 'Tips' || activePage === 'Tips' ? <TipsHover className="icon" /> : <Tips className="icon" />} Tips
          </button>
        </li>
        <li>
          <button
            className={activePage === 'Library' ? 'active' : ''}
            onClick={() => handleClick('Library')}
            onMouseEnter={() => handleMouseEnter('Library')}
            onMouseLeave={handleMouseLeave}
          >
            {hoveredItem === 'Library' || activePage === 'Library' ? <LibraryHover className="icon" /> : <Library className="icon" />} Library
          </button>
        </li>

      </ul>
      
      <div className="settings">
        <button
          className={activePage === 'Settings' ? 'active' : ''}
          onClick={() => handleClick('Settings')}
        >
          <Settings className="icon" /> Settings
        </button>
      </div>
    </div>
  );
}

export default Sidebar;
