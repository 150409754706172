import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Feed } from './feed.svg';
import { ReactComponent as Ranking } from './ranking.svg';
import { ReactComponent as Tips } from './tips.svg';
import { ReactComponent as TipsHover } from './tipsHover.svg';
import { ReactComponent as Home } from './home.svg'; // Ajout de l'importation manquante
import { ReactComponent as HomeHover } from './homeHover.svg';
import { ReactComponent as Awards } from './awards.svg'; // Ajout de l'importation manquante
import { ReactComponent as AwardsHover } from './awardsHover.svg';
import { ReactComponent as FeedHover } from './feedHover.svg';
import { ReactComponent as RankingHover } from './rankingHover.svg';
import { ReactComponent as Library } from './library.svg';
import { ReactComponent as LibraryHover } from './libraryHover.svg';

export {
    Home,
    Awards,
    Settings,
    Feed,
    Ranking,
    Tips,
    HomeHover,
    AwardsHover,
    FeedHover,
    RankingHover,
    TipsHover,
    Library,
    LibraryHover
};