// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    margin: 25px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);
}

.composant {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
}

.settings-container h1 {
    margin-top: 60px;
    margin-left: 45px;
}

.menu-item {
    color: black;
    padding: 10px;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/pages/css/settings.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,kBAAkB;IAClB,YAAY;IACZ,2CAA2C;AAC/C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,qBAAqB;AACzB","sourcesContent":[".box {\n    background-color: white;\n    padding: 20px;\n    border-radius: 5px;\n    margin: 25px;\n    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.05);\n}\n\n.composant {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: start;\n}\n\n.settings-container h1 {\n    margin-top: 60px;\n    margin-left: 45px;\n}\n\n.menu-item {\n    color: black;\n    padding: 10px;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
