import React, { useState, useEffect } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Dropdown } from './selecteur'; // Assurez-vous que ce composant est correct
const apiUrl = process.env.REACT_APP_BACKEND_URL;

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const GraphComponent = () => {
  const [chartData, setChartData] = useState({
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      {
        label: "Monthly Stats",
        data: Array(12).fill(0), // Données initiales pour 12 mois
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  });

  const [selectedStat, setSelectedStat] = useState("view"); // Utilisée pour suivre la statistique sélectionnée
  const [fullData, setFullData] = useState([]); // Pour stocker toutes les données de l'API
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}/api/graphe-stats`, {
          withCredentials: true,
        });
        const data = response.data;

        console.log("Données reçues de l'API :", data);

        if (!data || !Array.isArray(data)) {
          throw new Error("Format de données invalide");
        }

        // Stockez toutes les données reçues dans fullData
        setFullData(data);

        const months = Array.from({ length: 12 }, (_, i) => i + 1);
        const formattedData = months.map((month) => {
          const monthData = data.find((d) => d.month === month);
          return monthData ? monthData.stats[selectedStat] : 0;
        });

        setChartData({
          labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [
            {
              label: `Monthly ${selectedStat}`,
              data: formattedData,
              backgroundColor: "#74C8EF",
              borderColor: "#74C8EF",
              borderRadius: 5,
              borderWidth: 1,
              barThickness: 35,
            },
          ],
        });
        setLoading(false);
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedStat]);

  const handleSelectChange = (selectedOption) => {
    setSelectedStat(selectedOption); // Met à jour la statistique sélectionnée
    console.log("Statistique sélectionnée :", selectedOption);
  };

  // Fonction pour formater les valeurs en "K" si elles sont supérieures à 1000
  // Fonction pour formater les valeurs en "K" ou "M" si elles sont supérieures à 1000 ou 1000000
  const formatValue = (value) => {
    if (value >= 1000000) {
      const formattedValue = (value / 1000000).toFixed(1);
      return formattedValue.endsWith('.0') ? `${Math.round(value / 1000000)}M` : `${formattedValue}M`; // Supprime le ".0"
    }
    if (value >= 1000) {
      const formattedValue = (value / 1000).toFixed(1);
      return formattedValue.endsWith('.0') ? `${Math.round(value / 1000)}K` : `${formattedValue}K`; // Supprime le ".0"
    }
    return value;
  };


  // Options personnalisées pour le graphique
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          font: {
            size: 16 // Augmente la taille de la police des labels de l'axe x
          }
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
          color: '#EEE',
        },
        ticks: {
          callback: function (value) {
            return formatValue(value); // Utilise la fonction de formatage pour les valeurs sur l'axe y
          },
          maxTicksLimit: 5,
          font: {
            size: 16 // Augmente la taille de la police des labels de l'axe y
          }
        }
      }
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: function (context) {
          let tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.className = 'chartjs-tooltip';
            document.body.appendChild(tooltipEl);
          }
          const tooltipModel = context.tooltip;

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.body.map(b => b.lines);
            let innerHtml = '<div class="custom-tooltip">';

            titleLines.forEach(function (title) {
                const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                const monthIndex = tooltipModel.dataPoints[0].dataIndex;
                const fullMonthName = monthNames[monthIndex];
                innerHtml += `<div class="tooltip-title" style="font-size: 14px; font-weight: 500;">${fullMonthName} 2024</div>`;
            });

            bodyLines.forEach(function (body, i) {
              const monthIndex = tooltipModel.dataPoints[0].dataIndex;
              const month = monthIndex + 1; // Mois correspondant (1-12)

              // Accédez directement à fullData pour obtenir les valeurs
              const monthData = fullData.find(d => d.month === month);
              const views = formatValue(monthData?.stats.view || 'N/A');
              const score = formatValue(monthData?.stats.score || 'N/A');
              const posts = formatValue(monthData?.stats.posts || 'N/A');
              const ranking = monthData?.stats.rank || 'N/A'; // Affiche correctement le ranking
              
                const customBody = `
                <div class="tooltip-body" style="font-size: 14px; font-weight: 500;"><span>Ranking</span> <span>#${ranking}</span></div>
                <div class="tooltip-body" style="font-size: 14px;font-weight: 500;"><span>Posts</span> <span>${posts}</span></div>
                <div class="tooltip-body" style="font-size: 14px;font-weight: 500;"><span>Views</span> <span>${views}</span></div>
                <div class="tooltip-body" style="font-size: 14px;font-weight: 500;"><span>Score</span> <span>${score}</span></div>
                `;
              innerHtml += customBody;
            });

            innerHtml += '</div>';
            tooltipEl.innerHTML = innerHtml;
          }

          const position = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.pointerEvents = 'none';
        }
      },
      legend: {
        display: false
      }
    }
  };

  return (
    <div style={{ height: "88%", width: "100%" }}>
      <Dropdown 
        label="Views"
        options={[
          { label: 'Views', value: "view" },
          { label: 'Score', value: "score" },
          { label: 'Posts', value: "posts" }
        ]}
        onSelect={handleSelectChange} 
      />
      <div style={{ height: 20 }}></div>

      {loading ? (
        <p>Chargement des données...</p>
      ) : (
        <Bar
          data={chartData}
          options={options} // Applique les options personnalisées ici
        />
      )}
    </div>
  );
};

export default GraphComponent;
